import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "@auth/Auth.duck";
import { clearDashboard } from "@dashboard/dashboard.duck";
import styles from "./Topbar.module.scss";
import reg from "@graphics/icons/registrarse.svg";
import logoBlueseed from "@graphics/logoBlueseed.svg";
import menuIcon from "@graphics/menu_icon.webp";
import warningIcon from "@graphics/warning_icon.svg";
import { push } from "connected-react-router";
import checkPendingDocs from "../../../utils/checkPendingDocs.js";
import { BRAND_LOW } from "@utils/constants";
import NotificationIcon from "@graphics/icons/notification.svg"
import { Modal, notification } from "antd";

const Grid = styled.div`
  display: grid;
  gap: ${(props) => (props.gap ? props.gap : "10px")};
  place-items: center;
  grid-template-columns: ${(props) =>
    props.template ? props.template : "1fr"};
`;

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      user: null,
      mobileMenuOpened: false,
      conocerMenuOpened: false,
      openNotification: false
    };
  }
  componentWillMount() {
    this.setMenu(this.props);
  }

  componentDidMount() {
    // console.log(this.props.selected)
  }

  componentWillReceiveProps(props) {
    this.setMenu(props);
  }

  setMenu(_props) {
    if (_props.auth.jwt) {
      this.setState({
        loggedIn: true,
        user: _props.auth.user.User,
      });
    } else {
      this.setState({ loggedIn: false, user: null });
    }
  }

  openMobileMenu() {
    this.setState({ mobileMenuOpened: !this.state.mobileMenuOpened });
  }

  openConocerMenu() {
    this.setState({ conocerMenuOpened: !this.state.conocerMenuOpened });
  }

  logout = () => {
    this.props.clearDashboard();
    this.props.logout();
    this.props.goToHome();
  };

  render() {
    const { location, handleShowImage } = this.props;
    if (this.state.loggedIn) {
      //if this.state.loggedIn
      //console.log(this.props.auth.user)
      const user_status = this.props.auth.user.UserStatus.slug;
      const notification_url = this.props.auth.user.User.notification_url
      var status_info = "";
      var statusColor = "";
      if (user_status === "indocumentado") {
        status_info = " Completa tu perfil y documentos";
        statusColor = "indocumentado";
      }
      if (user_status === "pre_aprobado") {
        status_info = " Estamos verificando tu información";
        statusColor = "validacion";
      }
      if (user_status === "aprobado") {
        status_info = " Ya puedes invertir";
        statusColor = "aprobado";
      }
      return (
        <React.Fragment>
          <div className={styles.topbar_desktop}>
            <Modal
              title=""
              visible={this.state.openNotification}
              footer={null}
              width={"700px"}
              onCancel={() => this.setState({ openNotification: false })}
            >
              {
                notification_url ? (
                  <div className={styles.notification_container}>
                    <iframe src={notification_url} allowfullscreen className={styles.notification_content}></iframe>
                  </div>
                ) : (<p>No hay notificaciones en este momento</p>)
              }
            </Modal>
            <div className={styles.menu_items_left}>

              <Link to="/dashboard">
                <img
                  className={styles.logo_topbar}
                  src={logoBlueseed}
                  alt={`logo ${BRAND_LOW}`}
                />
              </Link>

              <div>
                <Link to="/" className={styles.menu_item}>
                  Inicio
                  {this.props.selected === "inmuebles" ? (
                    <div className={styles.selected_item} />
                  ) : null}
                </Link>
              </div>

              <div>
                <Link to={{ pathname: "https://ayuda.soyweseed.com" }} className={styles.menu_item} target="_blank">
                  Ayuda
                </Link>
              </div>
              {location.pathname === "/" && ( // Verifica si estás en "/"
                <Link
                  to="/"
                  className={styles.menu_item}
                  onClick={() => {
                    handleShowImage();
                  }}
                >
                  <div>
                    Nuevas oportunidades
                  </div>
                </Link>
              )}
              {/* Conocer más: ninguna de las subpáginas está actualizada - no eliminar mientras - */}
              {/* <div>
                <Link
                  to="#"
                  onClick={() => this.openConocerMenu()}
                  className={styles.menu_item}
                >
                  <div className={styles.relative}>
                    Conocer más
                    {this.state.conocerMenuOpened ? (
                      <div className={styles.conocer_menu}>
                        <Grid gap="1em" template="repeat(3, 1fr)">
                          <div className={styles.conocer_header}>
                            <Link
                              to="/conocer/invertir"
                              className={styles.conocer_item}
                            >
                              <span className={styles.title}>
                                ¿Cómo invertir?
                              </span>
                            </Link>
                          </div>

                          <div className={styles.conocer_header}>
                              <span className={styles.title}>
                                Sobre Constructores
                              </span>
                            <Link
                              to="/conocer/seleccion"
                              className={styles.conocer_item}
                            >
                              <span className={styles.question}>
                                ¿Cómo es el proceso de selección de inmuebles?
                              </span>
                            </Link>
                          </div>

                          <div className={styles.conocer_header}>
                            <span className={styles.title}>
                            Sobre Weseed
                            </span>
                            <Link
                                to="/nosotros"
                                className={styles.conocer_item}
                              >
                              <span className={styles.question}>
                                  Nosotros
                              </span>
                            </Link>
                          </div>
                        </Grid>
                      </div>
                    ) : null}
                    {this.props.selected === "conocer" ? (
                      <div className={styles.selected_item} />
                    ) : null}
                  </div>
                </Link>
              </div> */}
            </div>

            <div className={styles.menu_items_right}>
              <Link to="/dashboard" className={styles.menu_item}>
                {this.state.user.type_person === "natural" ? (
                  <span className={styles.topbar_profile_name}>
                    {this.state.user.first_name} {this.state.user.last_name}
                    <span
                      className={styles.statusInfo + " " + styles[statusColor]}
                    >
                      {status_info}
                    </span>
                  </span>
                ) : (
                  <span className={styles.topbar_profile_name}>
                    {this.state.user.razon_social}
                    <span
                      className={styles.statusInfo + " " + styles[statusColor]}
                    >
                      {status_info}
                    </span>
                  </span>
                )}
                <br />
              </Link>
              <div className={styles.notification} onClick={() => this.setState({ openNotification: true })}>
                <img src={NotificationIcon} alt="notification icon" className={styles.notification_icon}/>
              </div>
              <Link to="#" className={styles.menu_item}>
                <p className={styles.signout_btn}>
                  <button onClick={this.logout}>Cerrar sesión</button>
                </p>
              </Link>
            </div>
          </div>

          <div className={styles.topbar_mobile}>
            <div className={styles.menu_items_left}>
              <Link to="/dashboard">
                <img
                  className={styles.logo_topbar}
                  src={logoBlueseed}
                  alt={`logo ${BRAND_LOW}`}
                />
              </Link>
            </div>
            <div className={styles.menu_items_right}>
              <div className={styles.notification} onClick={() => this.setState({ openNotification: true })}>
                <img src={NotificationIcon} alt="notification icon" className={styles.notification_icon}/>
              </div>
              <div
                className={styles.menu_icon}
                onClick={() => this.openMobileMenu()}
              >
                <img loading="lazy" src={menuIcon} alt="Menu" />
              </div>

              {this.state.mobileMenuOpened ? (
                <div className={styles.mobile_menu}>
                  {location.pathname === "/" && ( // Verifica si estás en "/"
                    <Link
                      to="/"
                      className={styles.mobile_menu_item}
                      onClick={() => {
                        handleShowImage();
                      }}
                    >
                      <div>
                        Nuevas oportunidades
                      </div>
                    </Link>
                  )}
                  <div>
                    <Link to="/" className={styles.mobile_menu_item}>
                      Inicio
                      {this.props.selected === "inmuebles" ? (
                        <div className={styles.selected_item} />
                      ) : null}
                    </Link>
                  </div>
                  <div>
                    <Link to="/contacto" className={styles.mobile_menu_item}>
                      Contacto
                      {this.props.selected === "contacto" ? (
                        <div className={styles.selected_item} />
                      ) : null}
                    </Link>
                  </div>

                  <div>
                    <Link to={{ pathname: "https://ayuda.soyweseed.com" }} className={styles.mobile_menu_item} target="_blank">
                      Ayuda
                      {this.props.selected === "faq" ? (
                        <div className={styles.selected_item} />
                      ) : null}
                    </Link>
                  </div>

                  <div>
                    <Link to="#" className={styles.menu_item}>
                      <p className={styles.signout_btn}>
                        <button onClick={this.logout}>Cerrar sesión</button>
                      </p>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={styles.topbar_desktop}>
            <div className={styles.menu_items_left}>
              <Link to="/">
                <img
                  src={logoBlueseed}
                  className={styles.logo_topbar}
                  alt="Logo Weseed"
                />
              </Link>
            </div>

            <div className={styles.menu_items_right}>
              <Link to="/inmuebles" className={styles.menu_item}>
                <div>
                  Inversiones
                  {this.props.selected === "inmuebles" ? (
                    <div className={styles.selected_item} />
                  ) : null}
                </div>
              </Link>

              {/* Conocer más: ninguna de las subpáginas está actualizada - no eliminar mientras - */}
              {/* <Link
                to="#"
                onClick={() => this.openConocerMenu()}
                className={styles.menu_item}
              >
                <div className={styles.relative}>
                  Conocer más
                  {this.state.conocerMenuOpened ? (
                    <div className={styles.conocer_menu}>
                      <Grid gap="1em" template="repeat(0, 1fr)">
                        <div className={styles.conocer_header}>
                          <Link
                            to="/conocer/invertir"
                            className={styles.conocer_item}
                          >
                          <span className={styles.title}>
                              ¿Cómo invertir?
                            </span>
                          </Link>
                        </div>

                        <div className={styles.conocer_header}>
                          <Link
                            to="/conocer/seleccion"
                            className={styles.conocer_item}
                          >
                            <span className={styles.title}>
                              ¿Cómo es el proceso de selección de inmuebles?
                            </span>
                          </Link>
                        </div>

                        <div className={styles.conocer_header}>
                          <Link
                            to="/nosotros"
                            className={styles.conocer_item}
                          >
                            <span className={styles.title}>
                                Nosotros
                            </span>
                          </Link>
                        </div>
                      </Grid>
                    </div>
                  ) : null}
                  {this.props.selected === "conocer" ? (
                    <div className={styles.selected_item} />
                  ) : null}
                </div>
              </Link> */}

              <Link to="/contacto" className={styles.menu_item}>
                <div>
                  Contacto
                  {this.props.selected === "contacto" ? (
                    <div className={styles.selected_item} />
                  ) : null}
                </div>
              </Link>

              <Link to={{ pathname: "https://ayuda.soyweseed.com" }} className={`${styles.menu_item} ${styles.last_menu_item}`} target="_blank">
                <div>
                Ayuda
                  {/* <Icon type="question-circle" className={styles.f_icon} theme="filled" style={{ marginLeft: '4px' }} />{" "}*/}
                  {this.props.selected === "faq" ? (
                    <div className={styles.selected_item} />
                  ) : null}
                </div>
              </Link>              
              {location.pathname === "/" && ( // Verifica si estás en "/"
                <Link
                  to="/"
                  className={styles.nuevas_oportunidaddes}
                  onClick={() => {
                    handleShowImage();
                  }}
                >
                  <div>
                    Nuevas oportunidades
                  </div>
                </Link>
              )}
              <div className={styles.btn_register}>
                <Link to="/login">
                  <img loading="lazy" src={reg} alt="registro-login" />
                  <span className={styles.btn_register_text}>Iniciar sesión</span>
                </Link>
              </div>
            </div>
          </div>

          <div className={styles.topbar_mobile}>
            <div className={styles.menu_items_left}>
              <Link to="/">
                <img
                  src={logoBlueseed}
                  className={styles.logo_topbar}
                  alt="Logo Weseed"
                />
              </Link>
            </div>
            <div className={styles.menu_items_right}>
              <Link to="/login">
                <div className={styles.btn_register}>
                  <img
                    loading="lazy"
                    src={reg}
                    className={styles.reg_img}
                    alt="registro-login"
                  />{" "}
                  <span className={styles.btn_register_text}>Iniciar sesión</span>
                </div>
              </Link>

              <div
                className={styles.menu_icon}
                onClick={() => this.openMobileMenu()}
              >
                <img loading="lazy" src={menuIcon} alt="Menu" />
              </div>

              {this.state.mobileMenuOpened ? (
                <div className={styles.mobile_menu}>
              {location.pathname === "/" && ( // Verifica si estás en "/"
                <Link
                  to="/"
                  className={styles.mobile_menu_item}
                  onClick={() => {
                    handleShowImage();
                  }}
                >
                  <div>
                    Nuevas oportunidades
                  </div>
                </Link>
              )}
                  <div>
                    <Link to="/nosotros" className={styles.mobile_menu_item}>
                      Nosotros
                    </Link>
                  </div>
                  <div>
                    <Link to="/inmuebles" className={styles.mobile_menu_item}>
                      Inversiones
                    </Link>
                  </div>

                  <div>
                    <Link
                      to="/conocer/seleccion"
                      className={styles.mobile_menu_item}
                    >
                      Conocer más
                    </Link>
                  </div>

                  <div>
                    <Link to="/contacto" className={styles.mobile_menu_item}>
                      Contacto
                    </Link>
                  </div>
                  <div>
                    <Link to={{ pathname: "https://ayuda.soyweseed.com" }} className={styles.mobile_menu_item} target="_blank">
                    Ayuda
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// pass the needed actions/reducers from reducers/actions into props of this component
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToHome: () => push("/"),
      clearDashboard,
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));
